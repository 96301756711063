import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import SEO from "~/components/Seo/Seo"
import { Col, Container, PrismicTextarea, Row } from "~/components/ui"
import { PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./legal-notices.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: LegalNoticesQuery
}

function LegalNoticesPage(props: PropTypes) {
  const intl = useIntl()
  const text = props.data.data.data.conditions
  return (
    <div className={css.legalNotices}>
      <SEO
        title={intl.formatMessage({ id: "path:legal-notices" })}
        location={props.location}
      />

      <Container>
        <Row>
          <Col offset={[0, 1]} width={[12, 10]}>
            <PrismicTextarea text={text} />
          </Col>
        </Row>
      </Container>
    </div>
  )
  //
}

interface LegalNoticesQuery {
  data: {
    data: {
      conditions: PrismicStructuredText
    }
  }
}

export const query = graphql`
  query LegalNoticesPage($lang: String) {
    data: prismicLegals(lang: { eq: $lang }, data: { type: { eq: "tos" } }) {
      data {
        conditions {
          ...PrismicStructuredText
        }
      }
    }
  }
`

export default withTemplate(LegalNoticesPage)
